import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import frame from "../../assets/landpage/frame.svg";
// import abcd from "../../assets/abcd.jpg";
import "./HoneyBees.css";
// import { Link } from 'react-router-dom';
import bee from "../../assets/bee color 1.svg";
import { baseCEXURL, getAllAffiliateUser } from "../../services/api";
import LandHeader from "../LandingHeader/LandHeader";
import BeeHeader from "./BeeHeader";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import hive2 from "../../assets/landpage/Crypto bee copy.png";
import htable from "../../assets/landpage/crypto bee perks for Light mode.png";
import htable2 from "../../assets/landpage/crypto bees perks for dark Mode.png";
import ActionPacks from "../../assets/hivebanner/action.png";
import TokenPacks from "../../assets/hivebanner/token.png";
const HoneyBees = () => {
  const [topRatedCbees, setTopRatedCBees] = useState();
  const [themes, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "light"
  );
  console.log(themes, "themes");
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getAllAffiliateUser().then((data) => {
      // Define the names of the users you want to filter
      const targetNames = [
        "Queen Bee",
        "Grandma Honey",
        "Golden Sea Sailing",
        "Blockmichael22",
      ];

      // Map over targetNames to preserve the order and find corresponding users
      const orderedFilteredUsers = targetNames
        .map((name) => data.find((user) => user.accname === name))
        .filter((user) => user !== undefined); // Filter out undefined entries (if any user is not found)

      // Set the ordered filtered users to state
      setTopRatedCBees(orderedFilteredUsers);
    });
  }, []);

  return (
    <>
      {/* <LandHeader /> */}
      <BeeHeader />
      <Box mt={38}>
        <Box
          sx={{
            display: "flex",
            // flexDirection: "row",
            flexDirection: `${isMobile ? "column" : "row"}`,
            justifyContent: "center",
            alignItems: "center",
            width: "75%",
            mx: "auto",
            my: 25,
            maxWidth: "1520px",
          }}
        >
          {isMobile && (
            <Box
              component="img"
              sx={{
                width: "60%",
                // mb: 4
              }}
              alt=""
              src={bee}
            />
          )}
          <Box
            width={isMobile ? "85%" : "48%"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: `${isMobile ? "flex-start" : "flex-end"}`,
              alignSelf: "center",
              justifyContent: "start",
              pr: isMobile ? 0 : 5,
            }}
          >
            <Typography
              variant="text"
              fontSize={isMobile ? "15px" : "20px"}
              fontWeight={400}
              textAlign={"left"}
              mb={isMobile ? "-8px" : "-20px"}
              pr={isMobile ? 0 : "336px"}
            >
              Indexx Hive
            </Typography>
            <Typography
              variant="text"
              fontSize={isMobile ? "49px" : "80px"}
              fontWeight={700}
              textAlign={"left"}
              pr={isMobile ? 0 : "37px"}
            >
              Crypto Bee
            </Typography>

            <Typography
              fontSize={isMobile ? "11px" : "13px"}
              fontFamily={"Inter"}
              fontWeight={400}
              lineHeight={"28px"}
              textAlign={"left"}
              width={isMobile ? "auto" : "404.85px"}
              mr={isMobile ? 0 : "31px"}
            >
              A Crypto Bee represents the core membership within the Indexx Hive
              network, contributing to the community's growth and success in the
              crypto industry. While not assuming leadership responsibilities
              like Captain Bees, Crypto Bees actively participate in trading
              activities and engage with the community. They benefit from access
              to investment algorithms, learning resources, and simplified
              investment options offered through Hive Packs. Additionally, Honey
              Bees follow the guidance of Captain Bees, ensuring efficient hive
              activities and successful investment options. Through their
              dedication and collaboration within the Indexx ecosystem, Honey
              Bees contribute to the collective advancement of decentralized
              finance.
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                pr: isMobile ? 0 : 5,
                pl: isMobile ? 0 : 2,
                // width: ,
                width: `${isMobile ? "100%" : "449.94px"}`,
              }}
            >
              <Button
                variant="contained"
                disableTouchRipple
                sx={{
                  backgroundColor: "transparent",
                  border: "1px solid #FFB300",
                  borderRadius: "2px",
                  color: "#282828",
                  width: "49%",
                  px: isMobile ? 0 : 2.5,
                  py: 1,
                  textTransform: "none",
                  fontSize: `${isMobile ? "10px" : "13px"}`,
                  fontWeight: 600,
                  boxShadow: "none",
                  mt: 3,
                  "&:hover": {
                    backgroundColor: "#FFB300",
                    boxShadow: "none",
                  },
                }}
              >
                <a
                  href={`${baseCEXURL}/indexx-exchange/buy-sell/get-started-honeybee`}
                  style={{
                    textDecoration: "none", // Set textDecoration to "none" here
                    color: themes !== "dark" ? "#282828" : "#fff", // Add the color style for the anchor tag
                  }}
                >
                  Sign Up as Crypto Bee
                </a>
              </Button>

              <Button
                variant="contained"
                disableTouchRipple
                sx={{
                  backgroundColor: "#FFB300",
                  borderRadius: "2px",
                  color: "#282828",
                  width: "49.5%",
                  px: isMobile ? 0 : 3,
                  py: 1,
                  textTransform: "none",
                  fontSize: `${isMobile ? "10px" : "13px"}`,
                  fontWeight: 600,
                  boxShadow: "none",
                  mt: 3,
                  "&:hover": {
                    backgroundColor: "#FFD000",
                    boxShadow: "none",
                  },
                }}
              >
                <a
                  href={`${baseCEXURL}/indexx-exchange/buy-sell/login-honeybee`}
                  style={{
                    textDecoration: "none", // Set textDecoration to "none" here
                    color: "#282828", // Add the color style for the anchor tag
                  }}
                >
                  Sign In as Crypto Bee
                </a>
              </Button>
            </Box>
          </Box>

          {!isMobile && (
            <Box
              component="img"
              sx={{
                width: "30%",
                // mb: 4
              }}
              alt=""
              src={bee}
            />
          )}
        </Box>

        <Box
          // width={"55%"}
          sx={{
            display: "flex",
            flexDirection: "column",

            // ml: isMobile ? 0 : 3,
            maxWidth: "500px",
            width: "100%",
            margin: "auto",
            marginTop: "130px",
            marginBottom: "130px",
            padding: "20px",
          }}
        >
          <Typography
            variant="text"
            fontSize={isMobile ? "15px" : "20px"}
            fontWeight={400}
            textAlign={"left"}
          >
            Crypto Bee
          </Typography>

          <Typography
            variant="text"
            fontSize={isMobile ? "40px" : "80px"}
            fontWeight={700}
            textAlign={"left"}
          >
            Membership
          </Typography>
          <Typography
            variant="text"
            fontSize={isMobile ? "12px" : "13px"}
            fontWeight={400}
            textAlign={"left"}
            mt={2}
            maxWidth={500}
            mb={2}
          >
            Unlock exclusive benefits and opportunities in decentralized finance
            (DeFi) with Indexx Hive Membership. Gain access to advanced trading
            tools, educational resources, priority support, and rewarding
            opportunities. Join us today and elevate your crypto journey
          </Typography>
        </Box>

        <Box
          sx={{
            marginBottom: isMobile ? 0 : "120px",
          }}
        >
          <img
            style={{
              width: isMobile ? "100%" : "500px",
            }}
            alt=""
            src={hive2}
          />
          <br />
          <Button
            variant="contained"
            disableTouchRipple
            sx={{
              backgroundColor: "#8EDF78",
              borderRadius: "2px",
              color: "#282828",
              width: "280px",
              px: 3,
              py: 1,
              textTransform: "none",
              fontSize: isMobile ? "9px" : "13px",
              fontWeight: 600,
              boxShadow: "none",
              mt: 3,
              "&:hover": {
                backgroundColor: "#45C921", // Keep the same background color on hover
                color: "#282828", // Keep the same text color on hover
                boxShadow: "none",
              },
            }}
            href="/honey-bees"
          >
            Buy Crypto Bee Membership
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mb: 10,
            fontSize: `${isMobile ? "28px" : "50px"}`,
            fontWeight: 600,
          }}
        >
          Trending Captain Bees
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              width: "60%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Grid
              container
              // columns={{ xs: 1, sm: 12, md: 12 }}
              spacing={{ xs: 10, md: 2 }}
              maxWidth={"840px"}
            >
              {topRatedCbees?.map((item) => (
                <Grid item xs={12} sm={12} md={3}>
                  <>
                    <Link
                      to={`/captainbee/${item.Username}`}
                      style={{
                        textDecoration: "none",
                        color: "var(--body_color)",
                      }}
                    >
                      <Stack
                        direction="column"
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Box
                          sx={{
                            width: "171px",
                            height: "169px",
                            backgroundImage: `url(${frame})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            position: "relative",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                            // border:"none"
                          }}
                        >
                          {item?.photoIdFileurl === null ||
                          item?.photoIdFileurl === undefined ? null : (
                            <Box className="hexagon">
                              <img
                                alt=""
                                src={item?.photoIdFileurl}
                                // src={(item?.photoIdFileurl === undefined || item?.photoIdFileurl === null) ? frame : item?.photoIdFileurl}
                                width={"63px"}
                                height={"66px"}
                                ml={"-6px"}
                                border={"none"}
                              />
                            </Box>
                          )}
                        </Box>

                        <Typography
                          variant="text"
                          fontSize={isMobile ? "15px" : "20px"}
                          fontWeight={700}
                          mt={2}
                        >
                          {item?.accname}
                        </Typography>
                        <Typography
                          variant="text"
                          fontSize={isMobile ? "11px" : "13px"}
                          fontWeight={400}
                          mt={1}
                          mb={3}
                        >
                          {item?.firstname + " " + item?.lastname}
                        </Typography>
                      </Stack>
                    </Link>
                  </>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Button
            variant="contained"
            disableTouchRipple
            sx={{
              backgroundColor: "#FFB300",
              borderRadius: "2px",
              color: "#282828",
              width: `${isMobile ? "65%" : "280px"}`,
              px: 3,
              py: 1,
              textTransform: "none",
              fontSize: "13px",
              fontWeight: 600,
              boxShadow: "none",
              mt: 3,
              "&:hover": {
                backgroundColor: "#FFD000",
                boxShadow: "none",
              },
            }}
          >
            <a
              href="/bees"
              style={{
                textDecoration: "none", // Set textDecoration to "none" here
                color: "#282828", // Add the color style for the anchor tag
              }}
            >
              Select your Captain Bee Now!
            </a>
          </Button>
        </Box>
        <div className="powerpack_banner">
          <div style={{ transform: "translateY(50px)", marginBottom: "120px" }}>
            <h3
              style={{
                color: "black",
                fontSize: "52px",
                marginTop: "50px",
                fontWeight: "bold",
              }}
            >
              Action Packs
            </h3>
            <a
              href="https://cex.indexx.ai/indexx-exchange/power-pack"
              className="crypto-link"
            >
              Buy{" >"}
            </a>
          </div>
          <div
            className="BannerImgContainer"
            style={{
              width: "100%",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={ActionPacks} style={{ width: "80%" }} />
          </div>
        </div>
        <div className="cryptopack_banner">
          <div style={{ transform: "translateY(50px)", marginBottom: "120px" }}>
            <h3
              style={{
                color: "white",
                fontSize: "52px",
                marginTop: "50px",
                fontWeight: "bold",
              }}
            >
              Token Packs
            </h3>
            <a
              href="https://cex.indexx.ai/indexx-exchange/power-pack"
              className="crypto-link"
            >
              Buy{" >"}
            </a>
          </div>
          <div
            className="BannerImgContainer"
            style={{
              width: "100%",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={TokenPacks} style={{ width: "80%" }} />
          </div>
        </div>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
          }}
        >
          <img
            style={{
              width: "40%",
            }}
            alt=""
            src={themes === "light" ? htable : htable2}
          />
        </Box>
      </Box>
    </>
  );
};

export default HoneyBees;
