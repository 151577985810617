import React from "react";
import "./style.css"; // Import your CSS file for styling
import powerpack from "../../assets/hive-pack/01.png";
import cryptoPack from "../../assets/hive-pack/02.png";
import actionPack from "../../assets/hive-pack/3.png";
import tokenPack from "../../assets/hive-pack/04.png";

const HivePack = () => {
  return (
    <div className="container">
      {/* Center align section */}
      <div className="center-section">
        <h1>Hive Pack</h1>
        <p>
          Become a member and purchase the hive pack that best suits your needs!
        </p>
        <button>Become a Member</button>
      </div>

      {/* Responsive card section */}
      <div className="card-section">
        <div className="hive-pack-card">
          <h2>Power Pack</h2>
          <img src={powerpack} alt="Placeholder" height={"250px"} />

          <button className="yellow">Buy</button>
          <p>
            Power Packs provide diverse investment options and resources for all
            members.
          </p>
        </div>
        <div className="hive-pack-card">
          <h2>Crypto Pack</h2>
          <img src={cryptoPack} alt="Placeholder" height={"250px"} />

          <button className="yellow">Buy</button>
          <p>
            Offers strategic insights and investment opportunities exclusive to
            the crypto market.
          </p>
        </div>
        <div className="hive-pack-card">
          <h2>Action Pack</h2>
          <img src={actionPack} alt="Placeholder" height={"250px"} />

          <button className="green">Buy</button>
          <p>
            tailored for Crypto Bee members, providing strategic insights and
            investment opportunities.
          </p>
        </div>
        <div className="hive-pack-card">
          <h2>Token Pack</h2>
          <img src={tokenPack} alt="Placeholder" height={"250px"} />

          <button className="green">Buy</button>
          <p>
            Offers exclusive investment options in the form of coins, wallets,
            and tokens, catering to specific investment preferences.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HivePack;
