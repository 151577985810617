import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import bee from "../../assets/honeycomb/bee 2 1.svg";
import hat from "../../assets/honeycomb/hat2.png";
import frame from "../../assets/honeycomb/hive frame 1.svg";
// import logo from "../../assets/honeycomb/hive logo 2.svg";

// import nectar from "../../assets/details/nectar.svg";
// import nugget from "../../assets/details/nuggets2 3.svg";
// import jelly from "../../assets/details/Royal Jelly 3.svg";

import "../LandingHeader/LandHeader.css";
import { useTheme } from "@emotion/react";

const HoneyComb = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {/* <SubHeader /> */}
      <Box
        mt={19}
        // mt={35}
      >
        {/* <Box id="hive" sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "50%",
                mx: "auto",
                my: 25
            }}>
                
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                    <Typography variant="text" fontSize={"100px"} fontWeight={600} textAlign={"center"} ml={"-5px"}>
                        <Box
                        component="img"
                        alt=""
                        src={logo}
                        sx={{

                        }}
                    />
                        Indexx Hive
                    </Typography>

                    <Typography
                        fontSize={"13px"}
                        fontFamily={"Inter"}
                        fontWeight={400}
                        lineHeight={"28px"}
                        textAlign={"left"}
                        width={"72%"}
                        ml={14}
                    >
                    The Hive concept brings together franchise ownership, guidance, investment opportunities, and growth tools within a collaborative ecosystem. It allows individuals to learn, invest, and engage in cryptocurrency and stock trading with the support of experienced leaders and a well-structured platform. It's a way to create a thriving community where expertise is shared, and financial growth is cultivated.
                    </Typography>
                    <Typography
                        fontSize={"13px"}
                        fontFamily={"Inter"}
                        fontWeight={400}
                        lineHeight={"28px"}
                        textAlign={"left"}
                        width={"72%"}
                        ml={14}
                    >
                    Inside Indexx Hive:
                    </Typography>
                    <Typography
                        fontSize={"13px"}
                        fontFamily={"Inter"}
                        fontWeight={400}
                        lineHeight={"28px"}
                        textAlign={"left"}
                        width={"72%"}
                        ml={14}
                    >
                    1.The Honeycombs, the franchises
                    </Typography>
                    <Typography
                        fontSize={"13px"}
                        fontFamily={"Inter"}
                        fontWeight={400}
                        lineHeight={"28px"}
                        textAlign={"left"}
                        width={"72%"}
                        ml={14}
                    >
                    2.The Captain Bees, the owners of franchises
                    </Typography>
                    <Typography
                        fontSize={"13px"}
                        fontFamily={"Inter"}
                        fontWeight={400}
                        lineHeight={"28px"}
                        textAlign={"left"}
                        width={"72%"}
                        ml={14}
                    >
                    3.The Nectar, the users, investors, buyers
                    </Typography>
                    <Typography
                        fontSize={"13px"}
                        fontFamily={"Inter"}
                        fontWeight={400}
                        lineHeight={"28px"}
                        textAlign={"left"}
                        width={"72%"}
                        ml={14}
                        id="honeycomb"
                    >
                    4.The Honey Jar, the growth portfolio
                    </Typography>
                </Box>

            </Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // width: "40%",
            width: `${isMobile ? "56%" : "40%"}`,
            mx: "auto",
            mb: 25,
            mt: 4,
          }}
        >
          <Box
            component="img"
            alt=""
            src={frame}
            width={isMobile ? "40%" : "auto"}
            mb={isMobile ? "-20px" : "-60px"}
          />
          <Typography
            variant="text"
            fontSize={isMobile ? "40px" : "80px"}
            fontWeight={600}
            textAlign={"left"}
          >
            Honeycomb
          </Typography>
          <Box
            width={isMobile ? "auto" : "431.44px"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "center",
            }}
          >
            <Typography
              fontSize={isMobile ? "11px" : "13px"}
              fontFamily={"Inter"}
              fontWeight={400}
              lineHeight={"25px"}
              textAlign={"left"}
            >
              Much like a single honeycomb within a bustling beehive, the Indexx
              Exchange Honeycomb Platform operates seamlessly within the Hive
              framework, presenting a remarkable opportunity for Worker Bees to
              take charge of and run their own honeycomb. This honeycomb
              functions as one of Indexx Exchange's platforms. Imagine it as
              being akin to the proprietor of a shop on Etsy.com – it's a chance
              to earn income and profits through engagement with Nectar, the
              users, and investors.
            </Typography>
            <Typography
              fontSize={isMobile ? "11px" : "13px"}
              fontFamily={"Inter"}
              fontWeight={400}
              lineHeight={"25px"}
              textAlign={"left"}
              id="captainbee"
            >
              The Honeycomb encompasses all the features and benefits of the
              broader Hive, with the added advantage of comprehensive trainings
              to ensure success.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // width: "40%",
            width: `${isMobile ? "60%" : "40%"}`,
            mx: "auto",
            mb: 25,
            mt: 4,
          }}
        >
          <Box
            component="img"
            alt=""
            src={hat}
            width={isMobile ? "60%" : "auto"}
            mb={isMobile ? "-10px" : "-30px"}
          />
          <Typography
            variant="text"
            fontSize={isMobile ? "40px" : "80px"}
            fontWeight={600}
            textAlign={"left"}
          >
            Captain Bees
          </Typography>
          <Box
            width={isMobile ? "auto" : "457.75px"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "center",
            }}
          >
            <Typography
              variant="text"
              fontSize={isMobile ? "15px" : "25px"}
              fontWeight={400}
              textAlign={"left"}
              mb={1}
            >
              Owners of the Honeycombs
            </Typography>
            <Typography
              fontSize={isMobile ? "11px" : "13px"}
              fontFamily={"Inter"}
              fontWeight={400}
              lineHeight={"25px"}
              textAlign={"left"}
            >
              The Indexx Crypto Exchange Satellite Eco Platform is like a
              constellation of Honeycombs, each managed by a Captain Bee. These
              Captain Bees are the leaders who sign up to run their Honeycombs,
              creating spaces for users, investors, and revenues.
            </Typography>
            <Typography
              fontSize={isMobile ? "11px" : "13px"}
              fontFamily={"Inter"}
              fontWeight={400}
              lineHeight={"25px"}
              textAlign={"left"}
            >
              Think of Captain Bees as caretakers. They set up and maintain
              their unique Honeycombs within the ecosystem. Just as beekeepers
              tend to hives, Captain Bees ensure their Honeycombs stay updated
              with the latest features and benefits from the Hive.
            </Typography>
            <Typography
              fontSize={isMobile ? "11px" : "13px"}
              fontFamily={"Inter"}
              fontWeight={400}
              lineHeight={"25px"}
              textAlign={"left"}
              id="honeybee"
            >
              Captain Bees also oversee their Honeycombs' operations. They
              manage every aspect, making sure everything runs smoothly. It's
              all to provide the best experience for their users, known as
              Honeybees.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // width: "47%",
            width: `${isMobile ? "51%" : "47%"}`,
            mx: "auto",
            mb: 25,
            mt: 4,
          }}
        >
          <Box
            component="img"
            alt=""
            src={bee}
            width={isMobile ? "74%" : "auto"}
            mb={isMobile ? "-30px" : "-80px"}
          />
          <Typography
            variant="text"
            fontSize={isMobile ? "40px" : "80px"}
            fontWeight={600}
            textAlign={"left"}
          >
            Crypto Bee
          </Typography>
          <Box
            width={isMobile ? "auto" : "378.43px"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "center",
              ml: isMobile ? "4px" : 0,
            }}
          >
            <Typography
              variant="text"
              fontSize={isMobile ? "15px" : "25px"}
              fontWeight={400}
              textAlign={"left"}
              mb={1}
            >
              Investors and Users
            </Typography>
            <Typography
              fontSize={isMobile ? "11px" : "13px"}
              fontFamily={"Inter"}
              fontWeight={400}
              lineHeight={"25px"}
              textAlign={"left"}
            >
              Individuals who participate within the Honeycombs. They are
              investors, users, and traders who want to engage with the
              opportunities provided by the Hive. The Crypto Bees are guided by
              the Captain Bees, who help them navigate the world of investments
              in stocks and cryptocurrencies.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HoneyComb;
