import {
  Alert,
  Box,
  Grid,
  Paper,
  Rating,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import bees from "../../assets/landpage/captainbees.svg";
import bees_dark from "../../assets/landpage/captbees_dark.svg";
import bees_mobile from "../../assets/landpage/bees_mobile.svg";
import bees_mobile_dark from "../../assets/landpage/bees_mobile_dark.svg";
import pick_captain from "../../assets/landpage/pick_captain.svg";
import pick_captain_dark from "../../assets/landpage/pick_captain_dark.svg";
import React, { useEffect, useState } from "react";
// import copy from "../../assets/landpage/copyicon.svg";
import frame from "../../assets/landpage/frame.svg";
// import abcd from "../../assets/abcd.jpg";
import "./CaptainBees.css";
// import { Link } from 'react-router-dom';
import { baseCEXURL, getAllAffiliateUser } from "../../services/api";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

// const imgData = [
//     {
//         "id": "1",
//         "image": photo1,
//         "name": "Willie",
//         "link": "hive.indexx.ai/captainbee/willie",
//         "code": "1234567W",
//         "star": 5
//     },
//     {
//         "id": "2",
//         "image": photo2,
//         "name": "Rekha",
//         "link": "hive.indexx.ai/captainbee/rekha",
//         "code": "8794114Q",
//         "star": 4
//     },
//     {
//         "id": "3",
//         "image": photo3,
//         "name": "Kelly",
//         "link": "hive.indexx.ai/captainbee/kelly",
//         "code": "9561645S",
//         "star": 5
//     },
//     {
//         "id": "4",
//         "image": frame,
//         "name": "Arjun",
//         "link": "hive.indexx.ai/captainbee/arjun",
//         "code": "9561687E",
//         "star": 4
//     },
//     // {
//     //     "id": "5",
//     //     "image": frame,
//     //     "name": "Willie",
//     //     "link": "hive.indexx.ai/captainbee/willie",
//     //     "code": "1234567W",
//     //     "star": 3
//     // },
//     // {
//     //     "id": "6",
//     //     "image": frame,
//     //     "name": "Willie",
//     //     "link": "hive.indexx.ai/captainbee/willie",
//     //     "code": "1234567W",
//     //     "star": 5
//     // },
//     // {
//     //     "id": "7",
//     //     "image": frame,
//     //     "name": "Willie",
//     //     "link": "hive.indexx.ai/captainbee/willie",
//     //     "code": "1234567W",
//     //     "star": 1
//     // },
//     // {
//     //     "id": "8",
//     //     "image": frame,
//     //     "name": "Willie",
//     //     "link": "hive.indexx.ai/captainbee/willie",
//     //     "code": "1234567W",
//     //     "star": 2
//     // },
// ]

const Bees = () => {
  const [captainbees, setCaptainBees] = useState();
  const [topRatedCbees, setTopRatedCBees] = useState();
  const [initialRows, setInitialRows] = useState(10);
  const [totalRows, setTotalRows] = useState(10);

  // const totalRows = captainbees.length;
  const handleShowMore = () => {
    setInitialRows(initialRows + 10); // Increase by the number of rows you want to load on each click
  };
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getAllAffiliateUser().then((data) => {
      setCaptainBees(data);
      // Define the names of the users you want to filter
      const targetNames = [
        "Queen Bee",
        "Grandma Honey",
        "Golden Sea Sailing",
        "Blockmichael22",
      ];

      // Map over targetNames to preserve the order and find corresponding users
      const orderedFilteredUsers = targetNames
        .map((name) => data.find((user) => user.accname === name))
        .filter((user) => user !== undefined); // Filter out undefined entries (if any user is not found)

      // Set the ordered filtered users to state
      setTopRatedCBees(orderedFilteredUsers);
      setTotalRows(data.length);
    });
  }, []);

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSnackbarClick = () => {
    setOpen(true);

    const autoCloseTime = 1500; // 3 seconds

    setTimeout(() => {
      setOpen(false);
    }, autoCloseTime);
  };

  const copyClick = (code) => {
    navigator.clipboard.writeText(code);
    // alert("copied")
    handleSnackbarClick();
  };

  const [seltheme, setSeltheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setSeltheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      {/* <LandHeader /> */}
      <Box mt={27}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mb: 10,
          }}
        >
          <Box
            component="img"
            alt=""
            src={
              isMobile
                ? seltheme === "dark"
                  ? bees_mobile_dark
                  : bees_mobile
                : seltheme === "dark"
                ? bees_dark
                : bees
            }
            // src={isMobile ? bees_mobile : bees}
          />

          <Typography
            variant="text"
            fontSize={isMobile ? "12px" : "13px"}
            fontWeight={400}
            textAlign={"left"}
            mt={2}
            maxWidth={500}
            mb={2}
          >
            A Captain Bee is a central figure within the Indexx Hive network,
            tasked with leading and mentoring a team of Crypto Bees in the
            crypto industry. They guide collective decisions, participate in
            trading activities, and earn commissions from colony purchases. With
            access to exclusive benefits like investment algorithms and learning
            resources, Captain Bees play a crucial role in fostering growth
            within their colony. Through the Captain Bee Thriving Plan, they
            have the opportunity to thrive personally while shaping the future
            of decentralized finance within the Indexx ecosystem.{" "}
          </Typography>
        </Box>
        <br />
        <br />

        <Typography
          variant="text"
          fontSize={isMobile ? "12px" : "46px"}
          fontWeight={500}
          textAlign={"center"}
          mt={2}
          maxWidth={500}
          mb={100}
          marginBottom="20px"
        >
          Captain Bees of the week
        </Typography>

        <br />
        <br />
        <br />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              width: "60%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Grid
              container
              // columns={{ xs: 1, sm: 12, md: 12 }}
              spacing={{ xs: 10, md: 2 }}
              maxWidth={"840px"}
            >
              {topRatedCbees?.map((item) => (
                <Grid item xs={12} sm={12} md={3}>
                  <>
                    <Link
                      to={`/captainbee/${item.Username}`}
                      style={{
                        textDecoration: "none",
                        color: "var(--body_color)",
                      }}
                    >
                      <Stack
                        direction="column"
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Box
                          sx={{
                            width: "171px",
                            height: "169px",
                            backgroundImage: `url(${frame})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            position: "relative",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                            // border:"none"
                          }}
                        >
                          {item?.photoIdFileurl === null ||
                          item?.photoIdFileurl === undefined ? null : (
                            <Box className="hexagon">
                              <img
                                alt=""
                                src={item?.photoIdFileurl}
                                // src={(item?.photoIdFileurl === undefined || item?.photoIdFileurl === null) ? frame : item?.photoIdFileurl}
                                width={"63px"}
                                height={"66px"}
                                ml={"-6px"}
                                border={"none"}
                              />
                            </Box>
                          )}
                        </Box>

                        <Typography
                          variant="text"
                          fontSize={"20px"}
                          fontWeight={700}
                          mt={2}
                        >
                          {item?.accname}
                        </Typography>
                        <Typography
                          variant="text"
                          fontSize={"13px"}
                          fontWeight={400}
                          mt={1}
                          mb={3}
                        >
                          {item?.firstname + " " + item?.lastname}
                        </Typography>
                      </Stack>
                    </Link>
                  </>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        {isMobile && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mt: 10,
                mb: 10,
              }}
            >
              <Box
                component="img"
                alt=""
                src={seltheme === "dark" ? pick_captain_dark : pick_captain}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Grid
                  container
                  // columns={{ xs: 1, sm: 12, md: 12 }}
                  spacing={{ xs: 12, md: 2 }}
                  maxWidth={"840px"}
                >
                  {captainbees?.map((item) => (
                    <Grid item xs={12} sm={12} md={3}>
                      <>
                        <Stack
                          direction="column"
                          justifyContent={"center"}
                          alignItems={"center"}
                          width={"100%"}
                          mb={4}
                        >
                          <Box
                            sx={{
                              width: "171px",
                              height: "169px",
                              backgroundImage: `url(${frame})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "center",
                              position: "relative",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignSelf: "center",
                              // border:"none"
                            }}
                          >
                            {item?.photoIdFileurl === null ||
                            item?.photoIdFileurl === undefined ? null : (
                              <Box className="hexagon">
                                <img
                                  alt=""
                                  src={item?.photoIdFileurl}
                                  // src={(item?.photoIdFileurl === undefined || item?.photoIdFileurl === null) ? frame : item?.photoIdFileurl}
                                  width={"63px"}
                                  height={"66px"}
                                  ml={"-6px"}
                                  border={"none"}
                                />
                              </Box>
                            )}
                          </Box>

                          <Typography
                            variant="text"
                            fontSize={"20px"}
                            fontWeight={700}
                            mt={2}
                          >
                            {item?.accname}
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignSelf: "center",
                              // gap:1
                            }}
                          >
                            <Stack
                              direction="column"
                              justifyContent={"center"}
                              alignItems={"flex-start"}
                              minWidth={"95px"}
                              alignSelf={"baseline"}
                            >
                              <Typography
                                variant="text"
                                fontSize={"13px"}
                                fontWeight={400}
                                mt={1}
                              >
                                Profile Link :
                              </Typography>
                              <Typography
                                variant="text"
                                fontSize={"13px"}
                                fontWeight={400}
                                mt={1}
                              >
                                Referral Code :
                              </Typography>
                              <Typography
                                variant="text"
                                fontSize={"13px"}
                                fontWeight={400}
                                mt={1.5}
                              >
                                Rating :
                              </Typography>
                            </Stack>
                            <Stack
                              direction="column"
                              justifyContent={"center"}
                              alignItems={"flex-start"}
                              minWidth={"50%"}
                              maxWidth={"80%"}
                            >
                              <Typography
                                variant="text"
                                fontSize={"13px"}
                                fontWeight={400}
                                mt={1}
                                noWrap
                                maxWidth={"100%"}
                              >
                                <Link
                                  to={`/captainbee/${item.Username}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "var(--body_color)",
                                  }}
                                >
                                  {"hive.indexx.ai/captainbee/" + item.Username}
                                </Link>
                              </Typography>
                              <Typography
                                variant="text"
                                fontSize={"13px"}
                                fontWeight={400}
                                mt={1}
                              >
                                <>
                                  {item?.userData?.referralCode ? (
                                    <>
                                      <Link
                                        to={
                                          baseCEXURL +
                                          "/indexx-exchange/buy-sell/get-started-honeybee?referral=" +
                                          item?.userData?.referralCode
                                        }
                                        style={{
                                          textDecoration: "none",
                                          color: "var(--body_color)",
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {item?.userData?.referralCode}{" "}
                                      </Link>
                                      <ContentCopyIcon
                                        fontSize="15px"
                                        onClick={() =>
                                          copyClick(
                                            item?.userData?.referralCode
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      />
                                      <Snackbar
                                        open={open}
                                        autoHideDuration={1500} // You can set a default auto-hide duration here (6 seconds in this example)
                                        onClose={handleClose}
                                        sx={{
                                          mt: 10,
                                        }}
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                      >
                                        <Alert
                                          onClose={handleClose}
                                          severity="success"
                                          sx={{
                                            background: "#ffb300",
                                            color: "#000",
                                          }}
                                        >
                                          Referral Code Copied
                                        </Alert>
                                      </Snackbar>
                                    </>
                                  ) : (
                                    <Box className="code">
                                      {"No Refferal Code"}
                                    </Box>
                                  )}
                                </>
                              </Typography>
                              <Typography
                                variant="text"
                                fontSize={"13px"}
                                fontWeight={400}
                                mt={1}
                              >
                                <Rating
                                  name="read-only"
                                  value={item.star ? item.star : 4}
                                  readOnly
                                  size="large"
                                />
                              </Typography>
                            </Stack>
                          </Box>
                        </Stack>
                      </>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </>
        )}
        {!isMobile && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: 12,
            }}
          >
            <TableContainer
              component={Paper}
              style={{
                marginTop: 40,
                width: "70%",
                //   backgroundColor: `${darkMode ? '#040404' : '#fbfbfb'}`
              }}
            >
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#FFB300" }}>
                    <TableCell>
                      <Typography
                        variant="text"
                        fontSize={"20px"}
                        fontWeight={700}
                      >
                        Captain Bee
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="text"
                        fontSize={"20px"}
                        fontWeight={700}
                      >
                        Profile Link
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="text"
                        fontSize={"20px"}
                        fontWeight={700}
                      >
                        Referral Code
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                                        <Typography variant="text" fontSize={"15px"} fontWeight={700}>
                                            Team Captain Bee Referal Code
                                        </Typography>
                                    </TableCell> */}
                    <TableCell>
                      <Typography
                        variant="text"
                        fontSize={"20px"}
                        fontWeight={700}
                      >
                        Rating
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {captainbees?.slice(0, initialRows).map((collection) => (
                    <TableRow
                      key={collection.id}
                      style={{ textDecoration: "none" }}
                    >
                      <TableCell>
                        <Link
                          to={`/captainbee/${collection.Username}`}
                          style={{
                            textDecoration: "none",
                            color: "var(--body_color)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignSelf: "center",
                            }}
                          >
                            {/* <Box component="img" alt="Collection Image" src={(collection?.photoIdFileurl === undefined || collection?.photoIdFileurl === null )? frame : collection?.photoIdFileurl} sx={{ width: 80, height: 80 }} /> */}
                            <Box
                              sx={{
                                minWidth: "80px",
                                minHeight: "80px",
                                backgroundImage: `url(${frame})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                position: "relative",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "center",
                                // border:"none"
                              }}
                            >
                              {collection?.photoIdFileurl === null ||
                              collection?.photoIdFileurl ===
                                undefined ? null : (
                                <Box className="small-hexagon">
                                  <img
                                    alt=""
                                    // src={abcd}
                                    src={collection?.photoIdFileurl}
                                    // src={(collection?.photoIdFileurl === undefined || collection?.photoIdFileurl === null) ? frame : collection?.photoIdFileurl}
                                    width={"30px"}
                                    height={"31px"}
                                    ml={"-2px"}
                                    border={"none"}
                                  />
                                </Box>
                              )}
                            </Box>
                            <Box alignSelf={"center"} ml={2}>
                              <Typography
                                variant="text"
                                fontSize={"20px"}
                                fontWeight={700}
                                textAlign={"center"}
                                style={{ verticalAlign: "center" }}
                                color={"var(--body_color)"}
                              >
                                {collection?.accname}
                              </Typography>
                              <br />
                              <Typography
                                variant="text"
                                fontSize={"13px"}
                                fontWeight={400}
                                textAlign={"center"}
                                style={{ verticalAlign: "center" }}
                                color={"var(--body_color)"}
                              >
                                {collection?.firstname +
                                  " " +
                                  collection?.lastname}
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/captainbee/${collection.Username}`}
                          style={{
                            textDecoration: "none",
                            color: "var(--body_color)",
                          }}
                        >
                          {"hive.indexx.ai/captainbee/" + collection.Username}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignSelf: "center",
                            gap: 1,
                          }}
                          className="reff"
                        >
                          <>
                            {collection?.userData?.referralCode ? (
                              <>
                                {/* <Link
                                                            to="/view-ref" 
                                                            style={{ textDecoration: "none", color: "var(--body_color)"}}
                                                            // target="_blank"
                                                            rel="noopener noreferrer"> */}
                                <Button
                                  variant="contained"
                                  disableTouchRipple
                                  sx={{
                                    backgroundColor: "#FFB300",
                                    borderRadius: "2px",
                                    color: "#282828",
                                    width: "100%",
                                    //   px: 10,
                                    //   py: 1,
                                    textTransform: "none",
                                    fontSize: "13px",
                                    fontWeight: 700,
                                    boxShadow: "none",
                                    "&:hover": {
                                      backgroundColor: "#FFD000",
                                      boxShadow: "none",
                                    },
                                  }}
                                  onClick={() =>
                                    navigate("/view-ref", {
                                      state: {
                                        id: 1,
                                        accname: collection.accname,
                                        firstname: collection.firstname,
                                        lastname: collection.lastname,
                                        refcode:
                                          collection.userData.referralCode,
                                        photoIdFileurl:
                                          collection.photoIdFileurl,
                                      },
                                    })
                                  }
                                >
                                  Crypto Bee/Captain Bee
                                </Button>
                                {/* </Link> */}
                                {/* <Link to={baseCEXURL + "/indexx-exchange/buy-sell/get-started-honeybee?referral=" + (collection?.userData?.referralCode)} style={{ textDecoration: "none", color: "var(--body_color)" }}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            {(collection?.userData?.referralCode)}
                                                        </Link> */}
                                {/* <ContentCopyIcon  fontSize='15px' onClick={() => copyClick(collection?.userData?.referralCode)} style={{cursor:"pointer"}}/>
                                                            <Snackbar
                                                                open={open}
                                                                autoHideDuration={1500} // You can set a default auto-hide duration here (6 seconds in this example)
                                                                onClose={handleClose}
                                                                sx={{
                                                                    mt:10,
                                                                }}
                                                                anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                                }}
                                                            >
                                                                <Alert onClose={handleClose} severity="success" sx={{background:"#ffb300", color:"#000"}}>
                                                                Referral Code Copied
                                                                </Alert>
                                                            </Snackbar> */}
                              </>
                            ) : (
                              <Box className="code">{"No Refferal Code"}</Box>
                            )}
                          </>
                        </Box>
                      </TableCell>
                      {/* <TableCell>
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignSelf: "center",
                                                gap:1
                                            }}
                                                className="reff"
                                            >
                                                <>
                                                    {collection?.userData?.referralCode ? (
                                                        <>
                                                        <Link to={"/sign-up?referral=" + (collection?.userData?.referralCode)} style={{ textDecoration: "none", color: "var(--body_color)" }}
                                                            // target="_blank"
                                                            rel="noopener noreferrer">
                                                            {(collection?.userData?.referralCode)}
                                                        </Link>
                                                            <ContentCopyIcon  fontSize='15px' onClick={() => copyClick(collection?.userData?.referralCode)} style={{cursor:"pointer"}}/>
                                                            <Snackbar
                                                                open={open}
                                                                autoHideDuration={1500} // You can set a default auto-hide duration here (6 seconds in this example)
                                                                onClose={handleClose}
                                                                sx={{
                                                                    mt:10,
                                                                }}
                                                                anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                                }}
                                                            >
                                                                <Alert onClose={handleClose} severity="success" sx={{background:"#ffb300", color:"#000"}}>
                                                                Referral Code Copied
                                                                </Alert>
                                                            </Snackbar>
                                                        </>
                                                    ) : (
                                                        <Box className="code">
                                                            {"No Refferal Code"}
                                                        </Box>
                                                    )}
                                                </>

                                            </Box>

                                        </TableCell> */}
                      <TableCell>
                        <Rating
                          name="read-only"
                          value={collection.star ? collection.star : 4}
                          readOnly
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {initialRows < totalRows && (
              <Button
                variant="contained"
                disableTouchRipple
                onClick={handleShowMore}
                sx={{
                  backgroundColor: "#FFB300",
                  borderRadius: "2px",
                  color: "#282828",
                  px: isMobile ? 6.1 : 10,
                  py: 1,
                  textTransform: "none",
                  fontSize: `${isMobile ? "9px" : "13px"}`,
                  fontWeight: "700",
                  boxShadow: "none",
                  mt: 3,
                  "&:hover": {
                    backgroundColor: "#FFD000",
                    boxShadow: "none",
                  },
                }}
              >
                Show More
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Bees;
