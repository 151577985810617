import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  Typography,
  Stack,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { baseCEXURL } from "../../services/api";
import { Link } from "react-router-dom";
import dummy from "../../assets/dummy.jpg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import honeybee from "../../assets/ref_code/honey_black.svg";
import honeybee_dark from "../../assets/ref_code/honey_white.svg";
import captainbee from "../../assets/ref_code/capt_black.svg";
import captainbee_dark from "../../assets/ref_code/capt_white.svg";
import frame from "../../assets/landpage/frame.svg";
import "./CaptainBees.css";
import { useTheme } from "@emotion/react";
import SubHeader from "../SubHeader/SubHeader";
import { useLocation } from "react-router-dom";

const ReferralPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();

  const [seltheme, setSeltheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setSeltheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSnackbarClick = () => {
    setOpen(true);

    const autoCloseTime = 1500; // 3 seconds

    setTimeout(() => {
      setOpen(false);
    }, autoCloseTime);
  };

  const copyClick = (code) => {
    navigator.clipboard.writeText(code);
    // alert("copied")
    handleSnackbarClick();
  };

  return (
    <>
      <SubHeader />
      <Box mt={27}>
        <Stack
          direction="column"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            sx={{
              width: "171px",
              height: "169px",
              backgroundImage: `url(${frame})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              position: "relative",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              // border:"none"
            }}
          >
            {location.state?.photoIdFileurl === null ||
            location.state?.photoIdFileurl === undefined ? null : (
              <Box className="hexagon">
                <img
                  alt=""
                  src={location.state?.photoIdFileurl}
                  width={"63px"}
                  height={"66px"}
                  ml={"-6px"}
                  border={"none"}
                />
              </Box>
            )}
          </Box>

          <Typography variant="text" fontSize={"20px"} fontWeight={700} mt={2}>
            {location.state.accname}
          </Typography>
          <Typography variant="text" fontSize={"13px"} fontWeight={400}>
            Your Leader Captain Bee
          </Typography>
          <Typography variant="text" fontSize={"13px"} fontWeight={400} mb={3}>
            {location.state?.firstname + " " + location.state?.lastname}
          </Typography>
        </Stack>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              mb: 10,
              width: "70%",
              maxWidth: "1064px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                alt=""
                src={seltheme === "dark" ? honeybee_dark : honeybee}
                mb={6.2}
              />
              <Box mt={4}>
                <Link
                  to={
                    baseCEXURL +
                    "/indexx-exchange/buy-sell/get-started-honeybee?referral=" +
                    location.state.refcode
                  }
                  style={{ textDecoration: "none", color: "var(--body_color)" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {location.state.refcode}{" "}
                </Link>
                <ContentCopyIcon
                  fontSize="15px"
                  onClick={() => copyClick(location.state.refcode)}
                  style={{ cursor: "pointer" }}
                />
                <Snackbar
                  open={open}
                  autoHideDuration={1500}
                  onClose={handleClose}
                  sx={{
                    mt: 10,
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ background: "#ffb300", color: "#000" }}
                  >
                    Referral Code Copied
                  </Alert>
                </Snackbar>
              </Box>
              <Link
                to={
                  baseCEXURL +
                  "/indexx-exchange/buy-sell/get-started-honeybee?referral=" +
                  location.state.refcode
                }
                style={{ textDecoration: "none", color: "var(--body_color)" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="contained"
                  disableTouchRipple
                  sx={{
                    backgroundColor: "#FFB300",
                    borderRadius: "2px",
                    color: "#282828",
                    width: "413px",
                    px: 10,
                    py: 1,
                    textTransform: "none",
                    fontSize: "13px",
                    fontWeight: 700,
                    boxShadow: "none",
                    mt: 3,
                    "&:hover": {
                      backgroundColor: "#FFD000",
                      boxShadow: "none",
                    },
                  }}
                >
                  Become a Crypto Bee
                </Button>
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                alt=""
                src={seltheme === "dark" ? captainbee_dark : captainbee}
                style={{ marginTop: "-10px" }}
              />
              <Box mt={4}>
                <Link
                  to={"/sign-up?referral=" + location.state.refcode}
                  style={{ textDecoration: "none", color: "var(--body_color)" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {location.state.refcode}{" "}
                </Link>
                <ContentCopyIcon
                  fontSize="15px"
                  onClick={() => copyClick(location.state.refcode)}
                  style={{ cursor: "pointer" }}
                />
                <Snackbar
                  open={open}
                  autoHideDuration={1500}
                  onClose={handleClose}
                  sx={{
                    mt: 10,
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ background: "#ffb300", color: "#000" }}
                  >
                    Referral Code Copied
                  </Alert>
                </Snackbar>
              </Box>
              <Link
                to={"/sign-up?referral=" + location.state.refcode}
                style={{ textDecoration: "none", color: "var(--body_color)" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="contained"
                  disableTouchRipple
                  sx={{
                    backgroundColor: "#FFB300",
                    borderRadius: "2px",
                    color: "#282828",
                    width: "413px",
                    px: 10,
                    py: 1,
                    textTransform: "none",
                    fontSize: "13px",
                    fontWeight: 700,
                    boxShadow: "none",
                    mt: 3,
                    "&:hover": {
                      backgroundColor: "#FFD000",
                      boxShadow: "none",
                    },
                  }}
                >
                  Become a Captain Bee
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReferralPage;
